import { App, defineAsyncComponent } from 'vue'

export const registerComponents = (app: App) => {
  const names = [
    'v-game-intro',
    'v-icon',
    'v-loader',
    'v-min-max-input',
    'v-promise-button',
    'v-results-shared',
    'v-scores',
    'v-timer-ring',
    'v-wordcloud',
  ]

  names.forEach((name) => {
    app.component(name, defineAsyncComponent(() => import(`../components/${name}/index.vue`)))
  })
}
