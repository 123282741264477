import { Vue } from 'vue-class-component'

import { randomInt, randomSign } from '/@front/shared/helpers'

interface Ball {
  x: number
  y: number
  velX: number
  velY: number
  radius: number
}

export default class Vballs extends Vue {
  private balls: Ball[] = []
  private screenWidth: number = 0
  private screenHeight: number = 0
  private velocity: number = 0.4
  private animationFrameReference: any = null
  private fps: number = 20
  private fpsInterval: number = 0
  private startTime: any = null
  private now: any = null
  private then: any = null
  private elapsed = 0
  private isActive: boolean = false

  private get ballAmount() {
    return 3
  }

  private get radius() {
    const screenDiagonal = Math.sqrt(this.screenHeight * this.screenHeight + this.screenWidth * this.screenWidth)
    return screenDiagonal / (this.ballAmount + 2)
  }

  private init() {
    if (window.innerWidth === this.screenWidth) {
      return
    }

    const el = document.querySelector('.v-balls') as Element
    const style = window.getComputedStyle(el)
    if (style.display === 'none') {
      return
    }

    this.isActive = false

    window.cancelAnimationFrame(this.animationFrameReference)

    this.initVariables()
    this.createBalls()
    this.update()

    setTimeout(() => {
      this.isActive = true
    }, 300)
  }

  private initVariables() {
    this.fpsInterval = 1000 / this.fps
    this.then = Date.now()
    this.startTime = this.then
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
  }

  private createBalls() {
    this.balls = []

    for (let i = 0; i < this.ballAmount; i++) {
      this.balls.push({
        x: randomInt(this.radius, this.screenWidth - this.radius),
        y: randomInt(this.radius, this.screenHeight - this.radius),
        velX: this.velocity * randomSign(),
        velY: this.velocity * randomSign(),
        radius: this.radius,
      })
    }
  }

  private update() {
    this.animationFrameReference = requestAnimationFrame(this.update)

    this.now = Date.now()
    this.elapsed = this.now - this.then

    if (this.elapsed <= this.fpsInterval) {
      return
    }

    this.then = this.now - (this.elapsed % this.fpsInterval)

    this.balls.forEach((ball: Ball, index) => {
      this.checkBoundaries(ball)
      this.checkBalls(ball, index)

      ball.x += ball.velX
      ball.y += ball.velY
    })
  }

  private checkBoundaries(ball: Ball) {
    const hiddenPercentage = 0.1

    const hitLeft = ball.x + ball.velX <= -ball.radius * hiddenPercentage
    const hitRight = ball.x + ball.velX >= this.screenWidth + ball.radius * hiddenPercentage
    const hitTop = ball.y + ball.velY <= -ball.radius * hiddenPercentage
    const hitBottom = ball.y + ball.velY >= this.screenHeight + ball.radius * hiddenPercentage

    if (hitLeft) {
      ball.x = -ball.radius * hiddenPercentage
      ball.velX = -ball.velX
    }

    if (hitRight) {
      ball.x = this.screenWidth + ball.radius * hiddenPercentage
      ball.velX = -ball.velX
    }

    if (hitTop) {
      ball.y = -ball.radius * hiddenPercentage
      ball.velY = -ball.velY
    }

    if (hitBottom) {
      ball.y = this.screenHeight + ball.radius * hiddenPercentage
      ball.velY = -ball.velY
    }
  }

  private checkBalls(ball: Ball, index: number) {
    for (let j = index + 1; j < this.balls.length; j++) {
      const b: Ball = this.balls[j]

      const dx = b.x - ball.x
      const dy = b.y - ball.y
      const dist = Math.sqrt(dx * dx + dy * dy)
      const hit = dist <= ball.radius + b.radius

      if (hit) {
        const normalX = dx / dist
        const normalY = dy / dist
        const midpointX = (ball.x + b.x) / 2
        const midpointY = (ball.y + b.y) / 2

        ball.x = midpointX - normalX * ball.radius
        ball.y = midpointY - normalY * ball.radius
        b.x = midpointX + normalX * b.radius
        b.y = midpointY + normalY * b.radius

        let dVector = (ball.velX - b.velX) * normalX
        dVector += (ball.velY - b.velY) * normalY
        const dFactor = 1
        const dvx = (dVector * normalX) / dFactor
        const dvy = (dVector * normalY) / dFactor

        ball.velX -= dvx
        ball.velY -= dvy
        b.velX += dvx
        b.velY += dvy
      }
    }
  }

  private monitorScreenSize() {
    window.addEventListener('resize', this.init, {
      passive: true,
      capture: true,
    })
  }

  public beforeUnmount() {
    window.removeEventListener('resize', this.init, {
      capture: true,
    })
  }

  public mounted() {
    window.addEventListener('load', () => {
      this.init()
    })

    this.monitorScreenSize()
  }
}
