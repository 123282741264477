<div class="app-wrap">
    <v-balls></v-balls>

    <template v-if="!isLoading" v-cloak>
        <router-view :state="state"></router-view>
    </template>

    <v-flits></v-flits>

    <div class="app-loader" :class="{ ready: appIsReady }">
        <div></div>
        <div></div>
        <div></div>

        <h1></h1>

        <span class="logo"></span>
    </div>
</div>
