export default [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('/@front/views/app/auth/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'questionnaire',
        name: 'questionnaire',
        component: () => import('/@front/views/app/auth/questionnaire/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'lobby',
        name: 'lobby',
        component: () => import('/@front/views/app/auth/lobby/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'lobby/qr',
        name: 'qr',
        component: () => import('/@front/views/app/auth/qr/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'leaderboard',
        name: 'leaderboard',
        component: () => import('/@front/views/app/auth/leaderboard/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'games/:hash',
        name: 'game-play',
        component: () => import('/@front/views/app/auth/game/play/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'games/select',
        name: 'game-select',
        component: () => import('/@front/views/app/auth/game/select/index.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/room/update',
        name: 'room-update',
        component: () => import('/@front/views/app/auth/room/update/index.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/room/finished',
    name: 'room-finished',
    component: () => import('/@front/views/app/auth/room/finished/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('/@front/views/app/guest/rankings/index.vue'),
    meta: { requiresAuth: false },
  },
  {
    component: () => import('/@front/views/app/guest/index.vue'),
    path: '/',
    redirect: { name: 'room-select' },
    name: 'guest',
    children: [
      {
        path: 'room/select',
        name: 'room-select',
        component: () => import('/@front/views/app/guest/room/select/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'room/:code/login/',
        name: 'room-login',
        component: () => import('/@front/views/app/guest/room/login/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'room/:code/new-participant/',
        name: 'room-new-participant',
        component: () => import('/@front/views/app/guest/room/new-participant/index.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'room/:code/participants',
        name: 'room-participants',
        component: () => import('/@front/views/app/guest/room/participants/index.vue'),
        meta: { requiresAuth: false },
      },
    ],
  },
]
