export function empty(obj: any): boolean {
  return obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)
}

export function randomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function randomSign() {
  return Math.random() < 0.5 ? -1 : 1
}

export function randomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function randomHexColor() {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}
