<div class="v-flits" v-cloak>
    <ul>
        <transition-group name="flitsfade">
            <li v-for="item in flits" v-bind:class="item.level" v-bind:key="item.id" v-on:click="$flits.remove(item)">
                <div>
                    <p>
                        {{ item.message }}
                    </p>
                </div>
                <span class="close">
                    <v-icon name="x"></v-icon>
                </span>
            </li>
        </transition-group>
    </ul>
</div>
