/**
 * Fizzinity
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "http://incontext-fizzinity.test/api",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    server1: "http://incontext-fizzinity.test/api"
};
export type ParticipantStatus = "active" | "disabled";
export type RoomStatus = "pending" | "questionnaire" | "games" | "finished";
export type GameStatus = "intro" | "playing" | "processing" | "results" | "finished" | "discontinued";
export type Room = {
    hash: string;
    code: string;
    locale?: string;
    name: string;
    organization?: string;
    status: RoomStatus;
    isPaused?: boolean;
    score: number;
};
export type Participant = {
    hash: string;
    name: string;
    finishedQuestionnaire: boolean;
    isAdmin: boolean;
    status: ParticipantStatus;
    score: number;
    previousScore: number;
};
/**
 * submit a Participant's answer to the questionnaire
 */
export function submitQuestionnaireAnswers(body?: {
    answers?: {
        question: string;
        answer: string;
    }[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/questionnaire/answers", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Retrieve a token to authenticate with
 */
export function loginParticipant(body?: {
    room: string;
    name: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {
            token?: string;
        };
    }>("/participant/auth", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Your GET endpoint
 */
export function listQuestionnaireQuestions(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                "type"?: "know-your-colleague" | "dilemma";
                title: string;
                option: {
                    hash: string;
                    title: string;
                    image?: any;
                }[];
            }[];
        };
    }>("/questionnaire/questions", {
        ...opts
    });
}
/**
 * Create a new participant for a room
 */
export function createParticipant(body?: {
    room: string;
    name: string;
    email?: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: {
            token: string;
            participant: {
                hash: string;
                name: string;
                finishedQuestionnaire: boolean;
                isAdmin: boolean;
                status: ParticipantStatus;
                score: number;
                previousScore: number;
            };
        };
    } | {
        status: 422;
        data: {
            message?: string;
            errors?: {
                message?: string;
            }[];
        };
    }>("/participant", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Create a new room
 */
export function createRoom(signature: string, body?: {
    identifier: string;
    locale?: "nl" | "en";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                code: string;
                locale?: string;
                name: string;
                organization?: string;
                status: RoomStatus;
                isPaused?: boolean;
                score: number;
            };
        };
    }>("/rooms", oazapfts.json({
        ...opts,
        method: "POST",
        body,
        headers: {
            ...opts && opts.headers,
            Signature: signature
        }
    }));
}
export function postGameAnswer(game: string, body?: {
    answer?: string;
} | {
    answer?: string[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {};
    }>(`/games/${game}/answer`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Your GET endpoint
 */
export function getGameDetails(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                "type": string;
                role: "subject" | "executive" | "jury";
                status: GameStatus;
                selectsNextGame?: boolean;
                score?: number;
                time?: {
                    total: number;
                    remaining: number;
                };
                activeParticipant: {
                    hash: string;
                    name: string;
                };
                subject?: {
                    hash: string;
                    name: string;
                };
            };
        };
    }>(`/games/${game}`, {
        ...opts
    });
}
/**
 * Your GET endpoint
 */
export function getState(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data: {
                room?: Room;
                participant: Participant;
                activeGameHash?: any;
                activeGameStatus?: GameStatus;
            };
        };
    }>("/state", {
        ...opts
    });
}
/**
 * Your GET endpoint
 */
export function getRoomDetails(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: Room & {
                participants?: Participant[];
                nextGameSelector?: Participant;
            };
        };
    }>("/room", {
        ...opts
    });
}
/**
 * Set room details (only for room-admin)
 */
export function patchRoomDetails(body?: {
    name: string;
    organization: string;
    allowUseInRanking: boolean;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room", oazapfts.json({
        ...opts,
        method: "PATCH",
        body
    }));
}
/**
 * Your GET endpoint
 */
export function getParticipantsByRoom(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: Participant[];
        };
    }>(`/rooms/${code}/participants`, {
        ...opts
    });
}
/**
 * Your GET endpoint
 */
export function getGamesSelect(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data: {
                hash: string;
                "type": {
                    "class": string;
                    description: string;
                };
                subjectID: any;
            }[];
        };
    }>("/games/select", {
        ...opts
    });
}
/**
 * Select next game
 */
export function postGamesSelect(body?: {
    game: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/games/select", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
export function updateGameStatus(game: string, body?: {
    status?: GameStatus;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/games/${game}/status`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Your GET endpoint
 */
export function getGameResults(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            score: number;
            teamScore: number;
        };
    }>(`/games/${game}/results`, {
        ...opts
    });
}
export function startRoomPause(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/pause", {
        ...opts,
        method: "POST"
    });
}
export function endRoomPause(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/pause", {
        ...opts,
        method: "DELETE"
    });
}
export function setRoomStatus(body?: {
    status: "finished";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {
            data?: Room;
        };
    }>("/room/status", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
export function setParticipantStatus(participant: string, body?: {
    status?: "active" | "disabled";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/participants/${participant}/status`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Your GET endpoint
 */
export function getRanking({ token }: {
    token?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            tenant: {
                title: string;
            };
            rooms: {
                hash?: string;
                name: string;
                organization: string;
                score: number;
            }[];
        };
    }>(`/ranking${QS.query(QS.form({
        token
    }))}`, {
        ...opts
    });
}
/**
 * Your GET endpoint
 */
export function getSettings(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: object;
    }>("/settings", {
        ...opts
    });
}
