import _merge from 'lodash.merge'
import store from 'store'
import { Options, Vue } from 'vue-class-component'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'

import vBalls from '/@front/components/v-balls/index.vue'
import vFlits from '/@front/components/v-flits/index.vue'
import api, { HttpError } from '/@front/shared/api'
import { State } from '/@front/shared/interfaces'

@Options({
  components: {
    'v-flits': vFlits,
    'v-balls': vBalls,
  },
})
export default class App extends Vue {
  private state: State = {} as State
  private appIsReady: boolean = false
  private isLoading: boolean = true
  private tokenStorageKey = 'fizzinity.token'

  private get isPaused() {
    return this.state?.room?.isPaused || false
  }

  private checkStorageOnToken() {
    const token = store.get(this.tokenStorageKey)

    if (token) {
      this.setBearerToken(token)
      this.fetchState()
    } else {
      this.isLoading = false
    }
  }

  private monitorVisibilityEvents() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
    window.addEventListener('focus', this.handleVisibilityChange, false)
  }

  private monitorStateEvents() {
    this.$bus.$on('fetchState', this.fetchState)
    this.$bus.$on('updateState', this.updateState)
  }

  private monitorGlobalEvents() {
    this.$bus.$on('login', this.login)
  }

  private handleVisibilityChange() {
    setTimeout(() => {
      if (document.visibilityState !== 'hidden') {
        this.fetchState()
      }
    }, 300)
  }

  private login(token: string) {
    // @ts-ignore
    this.state = null
    this.setBearerToken(token)
    this.fetchState().then(() => {
      this.redirect()
    })
  }

  private setBearerToken(token: string) {
    store.set(this.tokenStorageKey, token)
    ;(api.defaults.headers as any).Authorization = `Bearer ${token}`
  }

  private async fetchState() {
    const token = store.get(this.tokenStorageKey)

    if (!token) {
      return
    }

    return api
      .getState()
      .then((response) => {
        const shouldUpdateState = JSON.stringify(response.data) !== JSON.stringify(this.state)

        if (shouldUpdateState) {
          this.updateState(response.data)
          this.$bus.$emit('stateUpdated')
        }
      })
      .catch((error) => {
        if (error instanceof HttpError) {
          throw error
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  private updateState(obj: any) {
    this.state = _merge(this.state, obj)
    window.$fizzinityState = this.state
  }

  private redirect() {
    const name: string = this.getRouteFromState()

    if (this.$route.name !== name) {
      this.$router
        .push({
          name,
          params: {
            ...(name === 'game-play' && { hash: this.state.activeGameHash }),
          },
        })
        .catch((error) => {
          const isDuplicatedError = isNavigationFailure(error, NavigationFailureType.duplicated)
          const isCancelError = isNavigationFailure(error, NavigationFailureType.cancelled)

          if (!(isDuplicatedError || isCancelError)) {
            throw error
          }
        })
    }
  }

  private getRouteFromState() {
    const map: any = {
      pending: 'room-select',
      questionnaire: 'lobby',
      games: 'game-play',
      finished: 'room-finished',
    }

    const key = this.state?.room?.status

    return key ? map[key] : undefined
  }

  public mounted() {
    this.checkStorageOnToken()
    this.monitorStateEvents()
    this.monitorGlobalEvents()
    this.monitorVisibilityEvents()

    setTimeout(() => {
      this.appIsReady = true
    }, 1200)
  }
}
